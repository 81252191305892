<template>
  <div class="content-box vision-box">
    <v-Header :isShowTitle="true" :showback="false" tittle='黄斑检查'>
      <template slot="tab">
        <div style="display:flex;align-items: center;">
          <img src="../../assets/imgs/eye.png" alt="">{{ $store.state.eyeText }}
        </div>
      </template>
      <template slot="right-icon">
        <div @click="$router.go(-1)">
          上一题
        </div>
      </template>
    </v-Header>
    <div v-if="!urve" class='page-content hasTitle'>
      <!-- <div>111</div> -->
      <macula-page></macula-page>
      <div class='align-left bottom-box'>
        <span class='base-color'>3 </span>方格图是否有模糊不清?
        <div class='flex flex-align-items flex-justify-content'>
          <span class='left-btn' @click="$router.push({ name: 'maculaIndexStep4' })">没有</span>
          <span class='right-btn' @click="curve = true">有</span>
        </div>
      </div>
    </div>
    <macula-index-step-5 v-if="curve"></macula-index-step-5>
  </div>
</template>

<script>
import vHeader from '../../components/header.vue'
import maculaPage from '../../components/maculaPage.vue'
import MaculaIndexStep5 from './maculaIndexStep5.vue';
export default {
  components: {
    vHeader, maculaPage,
    MaculaIndexStep5
  },
  data() {
    return {
      curve: false,
      canvasWidth: 10 / (this.$store.state.sys_param.Dpi * 1 / this.$store.state.sys_param.PPI * 2.45),
      canvasHeight: 10 / (this.$store.state.sys_param.Dpi * 1 / this.$store.state.sys_param.PPI * 2.45),
    };
  },

  mounted() {
  },
  created() {
  },

  methods: {
  },
};
</script>


 
<style lang="less" scoped>
@import "../../assets/less/vision.less";
</style>